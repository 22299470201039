import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import Hero from "../components/Hero"
import Subscribe from "../components/Subscribe"
import Image from "../components/Image";
import Action from '../components/Resource/Action';
import posterSrc from '../images/theme/img-activity-weight-based-bullying.jpg';
import {Link} from "gatsby";
import Video from '../components/Video';
import FooterCallout from "../partials/footerCallout";
// import ActivityGroup from "../components/Activity/ActivityGroup";

const EducatorsPage = () => (
  <Layout>
    <SEO title="Classroom Resources Old" />
    {/***************  HERO ***************/}
    <Section className="hero hero__classroom-resources">
      <Hero
        title={(
          <>
            <span className={'header-bar--yellow'}>Classroom</span>
            <span className={'header-bar--yellow'}>Resources</span>
          </>
        )}
        body="Empower students to be their best selves."
        image="img-home-lead.png"
      />
    </Section>

    {/***************  QUICKLINKS CONTAINER  ***************/}
    <Section className='quick-link-container'>
      <Container>
        <p className='easy-reading-font'>Every student deserves a chance at success. Empower your 4th and 5th grade students to be confident inside and out with evidence-based classroom resources focused on health, social and emotional learning (SEL), and body confidence.</p>
        <div className='quick-links'>
          <div className='quick-links__lead quick-links__section'>
            Quick Links
          </div>
          <div className='quick-links__links-container quick-links__section'>
            <a href='#confronting'>
              Confronting Comparisons to Build Body Confidence
            </a>
            <a href='#bullying'>
              Bullying & Teasing and Their Effect on Body Confidence
            </a>
          </div>
        </div>
      </Container>
    </Section>

    {/***************  INTRODUCTION ***************/}
    <Section>
      <Container>
        <Row>
          <Column span={6}>
            <Image filename={'placeholder-image.png'} />
          </Column>
          <Column span={6}>
            <h2>Introduction</h2>
            <p>According to numerous studies, low self-esteem in adolescents can negatively affect their performance in school, their relationships with others, and their own self-perception. Teach students how comparisons and bullying can negatively impact self-esteem, and practice strategies for negating its influence.</p>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  DIGITAL LESSON BUNDLE ONE ***************/}
    <Section className="midgray-background" id={"confronting"}>
      <Container>
        <h2>Confronting Comparisons to Body Confidence</h2>
        <p>Every student has unique skills, abilities, and ideas to offer the world. Teach your students the value of their own unique identities by introducing concepts like the healthy ideal vs. the appearance ideal, positive body talk, and the influence of social and celebrity media on body image.</p>
        <Row>
          <Column span={6}>
            <Video
              azure_src={'https://cep-de-primaryusprod-use2.streaming.media.azure.net/9bfe29c1-28f7-411a-94f6-f8751387b97f/Itron_MAIN_FINAL_MASTER_060220.ism/manifest'}
              posterSrc={posterSrc}
            />
          </Column>
          <Column span={6}>
            <h3>Masterclass</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ex felis, posuere vitae tincidunt non, semper ut purus. Donec nulla dolor, egestas vel volutpat et, dictum vitae nisi. Nam ornare dictum vestibulum. Maecenas massa lacus, ornare id congue eu, porta a velit. Suspendisse orci nibh, ultricies id finibus quis, tincidunt vel purus. Aliquam aliquam neque sit amet malesuada accumsan. Sed rutrum et metus eget finibus.</p>
            <Action
              label='Watch Video'
              type='video'
              azure_src={'https://cep-de-primaryusprod-use2.streaming.media.azure.net/d800c8e2-615d-415d-91d5-cc0fdfa4f969/HelpIsAllAroundUsDoseofKnowledge.ism/manifest'}
              posterSrc={posterSrc}
            >
              Watch Video
            </Action>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  DIGITAL LESSON BUNDLE TWO ***************/}
    <Section className="midgray-background" id={"bullying"}>
      <Container>
        <h2>Bullying & Teasing and Their Effect on Body Confidence</h2>
        <p>Bullying and teasing can have a profound impact on the way students view themselves and each other. Use this Digital Lesson Bundle to teach students the different strategies for confronting bullying and how to stand up for others.</p>
        <Row>
          <Column span={6}>
            <Video
              azure_src={'https://cep-de-primaryusprod-use2.streaming.media.azure.net/9bfe29c1-28f7-411a-94f6-f8751387b97f/Itron_MAIN_FINAL_MASTER_060220.ism/manifest'}
              posterSrc={posterSrc}
            />
          </Column>
          <Column span={6}>
            <h3>Masterclass</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ex felis, posuere vitae tincidunt non, semper ut purus. Donec nulla dolor, egestas vel volutpat et, dictum vitae nisi. Nam ornare dictum vestibulum. Maecenas massa lacus, ornare id congue eu, porta a velit. Suspendisse orci nibh, ultricies id finibus quis, tincidunt vel purus. Aliquam aliquam neque sit amet malesuada accumsan. Sed rutrum et metus eget finibus.</p>
            <Action
              label='Watch Video'
              type='video'
              azure_src={'https://cep-de-primaryusprod-use2.streaming.media.azure.net/d800c8e2-615d-415d-91d5-cc0fdfa4f969/HelpIsAllAroundUsDoseofKnowledge.ism/manifest'}
              posterSrc={posterSrc}
            >
              Watch Video
            </Action>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  CTA ***************/}
    <Section className={'classroom-resources-cta'}>
      <Container>
        <Subscribe
          trigger={
            <button
              className={'cta'}
            >
              Sign up for updates, resources coming soon!
            </button>
          }
        />
      </Container>
    </Section>

    {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
    <Section className={'squiggle'}>
      <Container>
        <h3>Download our Resources</h3>
        <Row>
          <Column
            span={5}
            className={'is-offset-1 resource'}
          >
            <Image
              className={'resource-image'}
              filename={'img-promo-vft.png'}
            />
            <h4 className={'bold-text'}>Virtual Field Trip</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
            <Link to={'/virtual-field-trip'}>
              Learn More
            </Link>
          </Column>
          <Column span={5} className={'resource'}>
            <Image
              className={'resource-image'}
              filename={'img-promo-resources.png'}
            />
            <h4 className={'bold-text'}>Family Resources</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
            <Link to={'/family-resources'}>
              Learn More
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className=" midgray-background">
      <Container>
        <Row>
          <Column span={7}>
            <p>
              When kids are confident and secure about who they are, they’re more likely to have a growth mindset. That means they can motivate themselves to take on new challenges and cope with and learn from mistakes.
            </p>

            <p>
              -{" "}
              <em>
                Understood.org
              </em>
            </p>
          </Column>
          <Column span={4} className={'is-offset-1 body-confidence-fact__container'}>
            <div className={'body-confidence-fact__container__image'}>
              <Image filename={'img-subfooter-3.png'} className={''}/>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  FOOTER CALLOUT ***************/}
    <FooterCallout/>

  </Layout>
);

export default EducatorsPage
